var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.tbData,"single-expand":_vm.singleExpand,"search":_vm.search,"loading":_vm.loadingTb,"loading-text":"Sedang Memuat Data...","expanded":_vm.expanded,"item-key":"id"},on:{"update:expanded":function($event){_vm.expanded=$event}},scopedSlots:_vm._u([{key:"item.tanggalSpan",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"p-2"},[_vm._v(" "+_vm._s(_vm.G_formatDate(item.tanggalSpan,'d-m-Y'))+" ")])]}},{key:"item.tanggalSpm",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"p-2"},[_vm._v(" "+_vm._s(_vm.G_formatDate(item.tanggalSpm,'d-m-Y'))+" ")])]}},{key:"item.amountSpan",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"p-2"},[_vm._v(" "+_vm._s(_vm.G_numFormat(item.amountSpan))+" ")])]}},{key:"item.nominal_emon",fn:function(ref){
var item = ref.item;
return [(item.amountSpm > item.amountSpan)?[_c('div',{staticClass:"zn-label-pagu-lebih",staticStyle:{"display":"inline-block"}},[_vm._v(_vm._s(_vm.G_numFormat(item.amountSpm)))])]:(item.amountSpm < item.amountSpan)?[_c('div',{staticClass:"zn-label-pagu-kurang",staticStyle:{"display":"inline-block"}},[_vm._v(_vm._s(_vm.G_numFormat(item.amountSpm)))])]:[_c('div',{staticStyle:{"display":"inline-block"}},[_vm._v(_vm._s(_vm.G_numFormat(item.amountSpm)))])]]}}])}),_c('G-dialogConfirm',{ref:"confirm"}),_c('G-notif',{ref:"notif"})],1)}
var staticRenderFns = []

export { render, staticRenderFns }