import LayoutAdmin from "@/layouts/LayoutAdmin";
import myTable from "./table";

export default {
  data() {
    return {
      search: "",
      loadingTb: false,
      isActive: [],
      summary: [],
      istable: [],
      listStatker: [],
      listRegister:[],
      dataTable:[],
      setDefaultTahun:new Date().getFullYear(),
      isShow: false,
     
    };
  },
  created() {
    this.$emit(`update:layout`, LayoutAdmin);
  },
  mounted() {

    this.G_GetRef("satker").then((data) => {
      this.listStatker = data;
      if (this.getUser.userGroup == "satker") {
        this.$refs.satker.setValue(this.getUser.kdsatker);
      } else {
        this.listStatker.unshift({
          text: "Semua Satker",
          value: "all",
        });
      }
    });

    this.G_GetRef("register").then((data) => {
      this.listRegister = data;
      this.listRegister.unshift({
        text: "Semua Register",
        value: "all",
      });
    });

    this.getSummary();
  },
  components: {
    myTable,
  },

  computed: {
  
  },
  methods: {
  

    getSummary() {
      this.loadingTb = true;
  
      let filter = [];

     

   
      this.G_GetAny("emon/spm/compare-with-omspan?tahun="+this.setDefaultTahun + filter).then((res) => {
        if (res.data.content) {
          this.dataTable = res.data.content;
        } else {
          this.dataTable = [];
        }
        this.loadingTb = false;
      });
     

      this.isShow = true;
    },
    cariData() {
      

      this.loadingTb = true;
  
      let filter = [];

      if (this.$refs.satker.getValue()) {
        if (this.$refs.satker.getValue() != 'all'){
          filter += '&kdsatker='+this.$refs.satker.getValue()
        }
      }

      if (this.$refs.register.getValue()) {
        if (this.$refs.register.getValue() != 'all'){
          filter += '&register='+this.$refs.register.getValue()
        }
      }

     

   
      this.G_GetAny("emon/spm/compare-with-omspan?tahun="+this.setDefaultTahun + filter).then((res) => {
        if (res.data.content) {
          this.dataTable = res.data.content;
        } else {
          this.dataTable = [];
        }
        this.loadingTb = false;
      });
     

      this.isShow = true;


    },
  
  
  },
};
