<template>
    <div>
        <v-data-table :headers="headers" :items="tbData" :single-expand="singleExpand" :search="search"
            :loading="loadingTb" loading-text="Sedang Memuat Data..." :expanded.sync="expanded" item-key="id">


             <template v-slot:item.tanggalSpan="{item}">
                <div class="p-2">
                    {{G_formatDate(item.tanggalSpan,'d-m-Y')}}
                </div>
            </template>
            <template v-slot:item.tanggalSpm="{item}">
                <div class="p-2">
                    {{G_formatDate(item.tanggalSpm,'d-m-Y')}}
                </div>
            </template>
            <template v-slot:item.amountSpan="{item}">
                <div class="p-2">
                    {{G_numFormat(item.amountSpan)}}
                </div>
            </template>
             <template v-slot:item.nominal_emon="{item}">
                 <template v-if="item.amountSpm > item.amountSpan">
                    <div style="display:inline-block;" class="zn-label-pagu-lebih">{{G_numFormat(item.amountSpm) }}</div> 
                </template>
                <template v-else-if="item.amountSpm < item.amountSpan">
                    <div style="display:inline-block;" class="zn-label-pagu-kurang">{{G_numFormat(item.amountSpm) }}</div> 
                </template>
                <template v-else>
                    <div style="display:inline-block;" >{{G_numFormat(item.amountSpm) }}</div> 

                </template>
            </template>
        </v-data-table>
        <G-dialogConfirm ref="confirm"/>
    

        <G-notif ref="notif" />    


    </div>
</template>
<script>
    export default {
        data() {
            return {
                expanded: [],
                singleExpand: false,
                headers: [
                    { text: 'Kode Satker', value: 'kdsatker' },
                    { text: 'Nama Satker', value: 'nmsatker' },
                    { text: 'Kode Program', value: 'kdprogram' },
                    { text: 'Kode Giat', value: 'kdgiat' },
                    { text: 'Kode Output', value: 'kdoutput' },
                    { text: 'Kode Akun', value: 'kdakun' },
                    { text: 'Kode Register', value: 'kdregister' },
                    { text: 'Tanggal Omspan', value: 'tanggalSpan',align:'right' },
                    { text: 'Tanggal Emon', value: 'tanggalSpm',align:'right' },
                    { text: 'Nominal Omspan', value: 'amountSpan',align:'right' },
                    { text: 'Nominal Emon', value: 'nominal_emon',align:'right' },

                ],
            }
        },
        methods: {
          
            getTable(){
                this.$emit('getTable');
            },
            
        },

        props: ['tbData', 'search', 'loadingTb'],
    }
</script>